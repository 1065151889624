export default {
    "apiUrl": 'https://247-api.myshre.com/admin/v1/',
    "mobileApiUrl": 'https://247-api.myshre.com/',
    "mediaUrl": 'https://247-api.myshre.com',
    // "apiUrl": 'https://stg-api.shre.cactusminds.com/admin/v1/',
    // "mobileApiUrl": 'https://stg-api.shre.cactusminds.com/',
    // "mediaUrl": 'https://stg-api.shre.cactusminds.com',
    // "apiUrl": 'http://localhost:8000/admin/v1/',
    // "mobileApiUrl": 'http://localhost:8000/',
    // "mediaUrl": 'http://localhost:8000',
}